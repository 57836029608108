frappe.ui.form.Toolbar = class ToolbalExtended extends frappe.ui.form.Toolbar {
	make_menu() {
		this.page.clear_icons();
		this.page.clear_menu();

		if (frappe.boot.desk_settings.form_sidebar) {
			this.make_navigation();
		}
		this.make_menu_items();
	}
}

const remove_custom_buttons = frm => {
	if (typeof frm.remove_buttons_labels !== "undefined") {
		remove_custom_buttons_from_page(frm)
	} else {
		frappe.xcall("lego_core.utils.form.get_remove_action_buttons", {
			doc_type: frm.doctype
		}).then(r => {
			frm.remove_buttons_labels = r
			remove_custom_buttons_from_page(frm)
		})
	}
}
const remove_custom_buttons_from_page = frm => {
	frm.remove_buttons_labels.forEach(row => {
		if (frm.custom_buttons[__(row[0])].length) {
			frm.remove_custom_button(__(row[0]), __(row[1]))
		}
	})
}

$(document).on("form-load", (e, frm) => {
	if (frm.remote_action_buttons_set) return
	frm.remote_action_buttons_set = true
	if (frappe.route_hooks.after_load) {
		let old_one = frappe.route_hooks.after_load
		frappe.route_hooks.after_load = frm => {
			old_one(frm)
			remove_custom_buttons(frm)
		}
	} else {
		frappe.route_hooks.after_load = remove_custom_buttons
	}
});
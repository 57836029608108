$(document).ready(() => {
    $('.search-icon').remove()
    $('#awesomplete_list_1').addClass('awesomplete-list')
    let $awsomeplete= $('.awesomplete')
    let $navbar_search_div=$(`<div class="search-bar">`).appendTo($awsomeplete)
    let $navbar_search = $('#navbar-search').addClass('searchbar').addClass('transform').appendTo($navbar_search_div)
    let $searchbar_toggler = $(`<div class="search-icon">
                                    <i class="fa fa-search fa-sm" aria-hidden="true"></i>
                                        </div>`).appendTo($navbar_search_div)
    $searchbar_toggler.click(function() {
        var clicks = $(this).attr('data-clicks');
        if (!cint(clicks)) {
            $('.transform').focus();
        }
    });
    $('.awesomplete-list').click(() => {
        $('.transform').blur();
    })
    
    $('.transform').on('focus', ()=>{
        $searchbar_toggler.attr('data-clicks', 1)
    });
    $('.transform').on('focusout', ()=>{
        setTimeout(() => {
            $searchbar_toggler.attr('data-clicks', 0)
        }, 300);
    });
    // Reomeve profile Image from Navbar
    $('.dropdown-navbar-user').remove()

    // On Session let login location.
    let href = "/app"
    if (frappe.boot.home_page==="dashboard-view") {
        href = `/app/${frappe.boot.home_page}${(frappe.boot.home_page==="dashboard-view"? "/"+ frappe.boot.home_page_name:"") }`
    }else if(frappe.boot.home_page) {
        href= frappe.boot.home_page
    }
    $('.navbar-brand.navbar-home').attr('href', href)
})
window.legoCore = {}
window.legoCore.ui = {}
window.legoCore.ui.LeftSideBar = class LeftSideBar {
    constructor() {
        // if (frappe.boot && frappe.boot.core_setup && frappe.boot.core_setup.show_left_sidebar) {
            this.parent_menu = ""
            this.root_menus = []
            this.data = {}
            this.disable_sidebar_toggle = false
        
            frappe.run_serially([
                ()=>this.get_page_name(),
                ()=>this.make_container(),
                ()=>this.set_user_info(),
                ()=>this.render_user_info(),
                ()=>this.setup_page_selector(),
                ()=>this.setup_sidebar_toggle(),
            ])
        // }
    }
    set_user_info(){
        this.user_info = frappe.boot.user_info[frappe.boot.user.name]
        this.user_info_menu_list = [
            {
                title :__("Profile"),
                icon : 'fa fa-cog',
                label : __("Profile"),
                link : `/app/user/${this.user_info.name}`

            },
            {
				title: __("Reload"),
				icon: 'fa fa-refresh',
				label: __("Reload"),
				onclick:()=>{
					return frappe.ui.toolbar.clear_cache();
				}
			},
            {
				title: __("Theme"),
				icon: 'fa fa-adjust',
				label: __("Theme"),
				onclick: ()=> {
                    let current_theme = document.documentElement.getAttribute("data-theme")==="light"?"dark":"light"
                    document.documentElement.setAttribute("data-theme", current_theme);
                    frappe.show_alert("Theme Changed", 3);

                    frappe.xcall("frappe.core.doctype.user.user.switch_theme", {
                        theme: toTitle(current_theme)
                    });
                    return
                }
			},
            {
				title: __("Logout"),
				icon: 'fa fa-power-off',
				label: __("Logout"),
				onclick: ()=>{
					return frappe.app.logout();
				}
			},
            {
				title: __("Set Defaults"),
				icon: 'fa fa-cogs',
				label: __("Logout"),
				onclick: ()=>{
					return frappe.ui.toolbar.setup_session_defaults();
				}
			}
        ]
    }
    render_user_info(){
        this.$userInfo = $(`<div class="user-profile-wrapper">`).prependTo(this.$sidebarWrapper2)
		this.$userProfileImage = $(`<div class="profile-img"> ${frappe.avatar(this.user_info.name, 'avatar-large')}</div>`).appendTo(this.$userInfo)
        // Profile image changer
        this.$image_changer = $(`<div class="image-changer"><i class="fa fa-camera fa-xs" aria-hidden="true"></i></div>`).appendTo(this.$userProfileImage)
        this.$image_changer.on('click', ()=>{
            let me = this;
            new frappe.ui.FileUploader({
                on_success: file=>{
                    if(file) {
                        frappe.xcall("frappe.client.set_value", {
                            doctype: 'User',
                            name: me.user_info.name,
                            fieldname: 'user_image',
                            value: file.file_url
                        }).then(r=>{
                            return frappe.ui.toolbar.clear_cache()
                        })
                    }
                }
            })
        })
		let $firstRow = $(`<div class="list-group">`).appendTo(this.$userInfo)
		$(`<div class="list-group-item user-name">${this.user_info.fullname}</div>`).appendTo($firstRow)
		this.$userInfoMenu = $(`<div class="list-inline text-center">`).addClass('user-action-menu').appendTo($(`<div class="list-group-item menu-list">`).appendTo($firstRow))
		this.user_info_menu_list.forEach(row=>{
			this.render_user_action_item(row)
		})

    }
    render_user_action_item(row) {
		let item = $(`<li><a href="${row.link || '#'}" data-toggle="tooltip" data-placement="bottom" title="${row.title || ''}">${row.icon?'<i class="'+ row.icon +'"></i>':row.label || ''}</a></li>`).appendTo(this.$userInfoMenu)
		if (row.onclick) {
			item.on('click', ()=>{
				row.onclick()
			})
		}
        item.children('a').tooltip()
	}

   
    get_page_name() {
        return frappe.xcall("lego_core.utils.menu.get_children", {
            is_root: true
        }).then(r=>{
            r.forEach(row=>{
                this.root_menus.push({"label": row.title, "value": row.value})
            })
            this.parent_menu=r.length?r[0].value:null
        })
    }

    setup_page_selector() {
        if (this.root_menus.length > 1) {
            this.page_selector = frappe.ui.form.make_control({
                parent: this.$sidebarSelector,
                df: {
                    fieldtype: "Select",
                    fieldname: "parent_menu",
                    default: this.parent_menu,
                    options: this.root_menus,
                    label: __("Select Menu"),
                    change: ()=>{
                        this.parent_menu = this.page_selector.get_value()
                        this.render_sidebar()
                    }
                }
            })
            this.page_selector.set_value(this.parent_menu)
            this.page_selector.refresh()
        }else {
            this.render_sidebar()
        }
    }  
    setup_sidebar_toggle() {
        this.toggoler = $(`<span class="sidebar-toggle-btn">
            <svg class="icon icon-md sidebar-toggle-placeholder">
                <use xlink:href="#icon-menu"></use>
            </svg>
            <span class="sidebar-toggle-icon">
                <svg class="icon icon-md">
                    <use xlink:href="#icon-sidebar-collapse">
                    </use>
                </svg>
            </span>
        </span>`).insertBefore($('.navbar-home'))
        if (this.disable_sidebar_toggle || !this.$sidebarWrapper2.length) {
            this.toggoler.remove();
        } else {
            this.toggoler.attr("title", __("Toggle Sidebar")).tooltip({
                delay: { "show": 600, "hide": 100 },
                trigger: "hover",
            });
            this.toggoler.click(() => {
                
                if (frappe.utils.is_xs() || frappe.utils.is_sm()) {
                    this.setup_overlay_sidebar();
                } else {
                    this.$sidebarWrapper.toggle();
                }
                $(document.body).trigger('toggleSidebar');
                this.update_sidebar_icon();
            });
        }
    }
    setup_overlay_sidebar() {
		this.$sidebarWrapper2.addClass('opened');
		$('<div class="close-sidebar">').hide().appendTo(this.$sidebarWrapper).fadeIn();
		let scroll_container = $('html')
			.css("overflow-y", "hidden");

        this.$sidebarWrapper.find(".close-sidebar").on('click', (e) => close_sidebar(e));
		this.$sidebarWrapper.on("click", "button:not(.dropdown-toggle)", (e) => close_sidebar(e));

		let close_sidebar = () => {
			scroll_container.css("overflow-y", "");
			this.$sidebarWrapper.find("div.close-sidebar").fadeOut(() => {
				this.$sidebarWrapper2.removeClass('opened')
					.find('.dropdown-toggle')
					.removeClass('text-muted');
			});
		};
	}
    update_sidebar_icon() {
        let sidebar_toggle_icon = this.toggoler.find('.sidebar-toggle-icon');
        let is_sidebar_visible = this.$sidebar.is(":visible");
        sidebar_toggle_icon.html(frappe.utils.icon(is_sidebar_visible ? 'sidebar-collapse' : 'sidebar-expand', 'md'));
    }
    make_container() {
        this._$body = $("#body").addClass("col-sm-10")
        this.$wrapper = $(`<div style="display: flex" class="container">`)
        this._$body.wrap(this.$wrapper)
        this.$sidebarWrapper = $(`<div class="col-sm-2 left-main-sidebar layout-side-section"></div>`).insertBefore(this._$body)
        this.$sidebarWrapper2 = $('<div class="list-sidebar overlay-sidebar hidden-xs hidden-sm sticky-sidebar">').appendTo(this.$sidebarWrapper)
        this.$sidebarSelector = $(`<div>`).appendTo(this.$sidebarWrapper2)
        this.$sidebar = $(`<ul class="accordion list-unstyled">`).appendTo(this.$sidebarWrapper2)
    }
    render_sidebar(parent, wrapper) {
        if (!parent) {
            parent = this.parent_menu
        }
        if (!wrapper) {
            this.$sidebar.attr('id', `accordion${parent}`)
            wrapper = this.$sidebar
        }
        if (!parent)
        return
        wrapper.html("")
        if (this.data && this.data[parent]) {
            this.render_links(wrapper, this.data[parent], parent)
        }else {
            this.fetch_date(parent).then(r=>{
                this.data[parent] = r
                this.render_links(wrapper, r, parent)
            })
        }
    }
    render_links(parent, links, parent_menu) {
        links.forEach(item=>{
            let route = item.link
            if (item.expandable) {
                route = '#'
            }else if (item.type !== "Custom URL" && !item.expandable) {
                let report_route = null
                if (item.type === "Report" && !item.is_query_report && item.report_doctype){
                    report_route = `${frappe.router.slug(item.report_doctype || "")}/view/report/${item.link_to}`
                }else if (item.type === "Workspace") {
                    report_route = `${frappe.router.slug(item.doc_type || "")}`
                }
                route = frappe.utils.generate_route({
                    name: item.doc_type,
                    type: item.type,
                    is_query_report: item.is_query_report,
                    doctype: item.report_doctype || "",
                    doc_view: item.doc_view || "",
                    route: report_route
                });
            }
            let $item = $(`<li class="list-item" ${(item.expandable? 'id="accordion' + item.value +'"':'')}><a href="${route}" class="link-item ellipsis standard-sidebar-item  ${(item.expandable? 'dropdown-item': '')} collapsed"  
            ${(item.expandable? `data-toggle="collapse" data-target="#flush-collapse${item.value}" aria-controls = "flush-collapse${parent_menu}" `: '')} type="${item.type}">
                    ${this.get_icon(item)} ${this.get_link_for_item(item)}
            </a></li>`).appendTo(parent)
            
            $item.on("click", "a", ()=>{
                if (item.expandable) {
                    if(!$item.children('ul').length) {
                        let $ul = $('<ul>').appendTo($item).attr({
                            "id":`flush-collapse${item.value}`,
                            "data-parent":`#accordion${parent_menu}`
                        }).addClass('render-items')
                        this.render_sidebar(item.value, $ul)
                    }
                } else if (item.options) {
                    frappe.route_options = item.options
                }
            })
        })
    }
    get_icon(item) {
        if (item.icon) {
            return `<i class = "${item.icon}"></i>`
        }else if (item.icon_image) {
            return `<img class="menu-icon-image" src="${item.icon_image}">`
        }
        return ''
    }
    get_link_for_item(item) {
        if (item.youtube_id)
            return `<span class="link-content help-video-link ellipsis" data-youtubeid="${item.youtube_id}">
                    ${item.title ? item.title : item.value}</span>`;
        return `<span class="link-content ellipsis">${item.title ? item.title : item.value}</span>`;
    }
    fetch_date (parent) {
        return frappe.xcall("lego_core.utils.menu.get_children", {parent: parent})
    }
}
window.legoCore.LeftSideBar = new window.legoCore.ui.LeftSideBar()

$(document).ready(()=>{
    $("#body").css("min-height", `${$(document).height() - 67}px`)
    localStorage.container_fullwidth = false
    return frappe.ui.toolbar.toggle_full_width()
})
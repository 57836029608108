
	frappe.templates['print_skeleton_loading'] = `<div class="print-format-skeleton">
	<div class="skeleton-header">
		<div class="letter-head row skeleton-section">
			<div class="col-xs-4">
				<div class="skeleton-card dark large"></div>
			</div>
		</div>
		<div class="print-heading skeleton-section">
			<div class="row">
				<div class="col-xs-5"><div class="skeleton-card dark large"></div></div>
			</div>
			<div class="row">
				<div class="col-xs-3">
					<small class="sub-heading">
						<div class="skeleton-card light"></div>
					</small>
				</div>
			</div>
		</div>
	</div>
	<div class="skeleton-body">
		<div class="row skeleton-section">
			<div class="col-xs-6 column-break">
				<div class="row">
					<div class="col-xs-6">
						<div class="skeleton-card dark"></div>
					</div>
					<div class="col-xs-6">
						<div class="skeleton-card light"></div>
					</div>
				</div>
			</div>
			<div class="col-xs-6 column-break">
				<div class="row">
					<div class="col-xs-5">
						<div class="skeleton-card light pull-right" style="width: 85%;"></div>
					</div>
					<div class="col-xs-7">
						<div class="skeleton-card light pull-right" style="width: 75%;"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="skeleton-section">
			<div class="skeleton-table">
				<div class="skeleton-table-header">
					<div class="skeleton-table-row">
						<div class="skeleton-table-column" style="width: 3%;">
							<div class="skeleton-card dark"></div>
						</div>
						<div class="skeleton-table-column" style="width: 17%;">
							<div class="skeleton-card dark" style="width: 75%;"></div>
						</div>
						<div class="skeleton-table-column" style="width: 20%;">
							<div class="skeleton-card dark" style="width: 75%;"></div>
						</div>
						<div class="skeleton-table-column" style="width: 28%;">
							<div class="skeleton-card dark pull-right" style="width: 90%;"></div>
						</div>
						<div class="skeleton-table-column" style="width: 12%;">
							<div class="skeleton-card dark pull-right" style="width: 100%;"></div>
						</div>
						<div class="skeleton-table-column" style="width: 20%;">
							<div class="skeleton-card dark pull-right" style="width: 70%;"></div>
						</div>
					</div>
				</div>
				<div class="skeleton-table-body">
					{% for (let i=0; i<4; i++) { %}
						<div class="skeleton-table-row">
							<div class="skeleton-table-column" style="width: 3%;">
								<div class="skeleton-card light"></div>
							</div>
							<div class="skeleton-table-column" style="width: 17%;">
								<div class="skeleton-card light" style="width: 75%;"></div>
							</div>
							<div class="skeleton-table-column" style="width: 20%;">
								<div class="skeleton-card light" style="width: 100%;"></div>
							</div>
							<div class="skeleton-table-column" style="width: 28%;">
								<div class="skeleton-card light pull-right" style="width: 50%;"></div>
							</div>
							<div class="skeleton-table-column" style="width: 12%;">
								<div class="skeleton-card light pull-right" style="width: 80%;"></div>
							</div>
							<div class="skeleton-table-column" style="width: 20%;">
								<div class="skeleton-card dark pull-right" style="width: 100%;"></div>
							</div>
						</div>
					{% } %}
				</div>
			</div>
		</div>

		<div class="skeleton-section">
			<div class="row">
				<div class="col-xs-6"></div>
				<div class="col-xs-6">
					<div class="row">
						<div class="col-xs-5">
							<div class="skeleton-card light"></div>
						</div>
						<div class="col-xs-7">
							<div class="skeleton-card dark pull-right" style="width: 80%;"></div>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-5">
							<div class="skeleton-card light" style="width: 80%;">
							</div>
						</div>
						<div class="col-xs-7">
							<div class="skeleton-card dark pull-right" style="width: 60%;">
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-5">
							<div class="skeleton-card light" style="width: 80%;"></div>
						</div>
						<div class="col-xs-7 text-right">
							<div class="skeleton-card dark pull-right" style="width: 60%;">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="skeleton-section">
			<div class="row">
				<div class="col-xs-6"></div>
				<div class="col-xs-6">
					<div class="row">
						<div class="col-xs-5">
							<div class="skeleton-card light"></div>
						</div>
						<div class="col-xs-7">
							<div class="skeleton-card dark pull-right" style="width: 80%;"></div>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-5">
							<div class="skeleton-card light" style="width: 90%;">
							</div>
						</div>
						<div class="col-xs-7">
							<div class="skeleton-card dark pull-right" style="width: 60%;">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="skeleton-footer">
		<div class="skeleton-card dark large" style="width: 45%;"></div>
		<div class="skeleton-card light" style="width: 60%;"></div>
		<div class="skeleton-card light" style="width: 35%;"></div>
		<div class="skeleton-card light" style="width: 35%;"></div>
	</div>
</div>
`;

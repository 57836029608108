frappe.views.ListView = class ListViewExtend extends frappe.views.ListView {
    get_list_view_settings() {
        this.add_print_button()
        // Super        
        return frappe.call("frappe.desk.listview.get_list_settings", {
            doctype: this.doctype,
        }).then((doc) => (this.list_view_settings = doc.message || {}));
    }
    add_print_button() {
        // Added Button
        let print_settings = frappe.model.get_doc(":Print Settings", "Print Settings");
        this.allow_print_for_draft = cint(print_settings.allow_print_for_draft);
        this.allow_print_for_cancelled = cint(print_settings.allow_print_for_cancelled);
        this.settings['button'] = {
            show: doc => {
                // Print
                let docstatus = cint(doc.docstatus);
                if (!frappe.boot.disable_list_print && (!this.meta.is_submittable || docstatus == 1 ||
                    (this.allow_print_for_cancelled && docstatus == 2) ||
                    (this.allow_print_for_draft && docstatus == 0))) {
                    // Print
                    return true
                }
                return false
            },
            get_description: doc => {
                return __(`Print ${doc.name}`)
            },
            get_label: doc => {
                return frappe.utils.icon('printer')
            },
            action: doc => {
                let wrapper = new frappe.ui.Dialog({
                    title: __("Print"),
                    size: "extra-large"
                })
                wrapper.page = frappe.ui.make_app_page({
                    parent: wrapper.$body
                })
                let print_view = new lego_core.ui.form.PrintView(wrapper);
                frappe.model.with_doc(this.doctype, doc.name, () => {
                    let frm = { doctype: this.doctype, docname: doc.name };
                    frm.doc = frappe.get_doc(this.doctype, doc.name);
                    frappe.model.with_doctype(this.doctype, () => {
                        frm.meta = frappe.get_meta(this.doctype);
                        print_view.show(frm);
                        wrapper.show()
                    });
                });
            }
        }
    }
}